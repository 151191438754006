import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { setPinPwd, getUserInfo } from "@/api/userInfo"; // 引入接口

export default {
  name: "pinCode",

  setup() {
    const route = useRouter();
    const pin = ref(""); // 存储 PIN 码

    const confirmPin = ref(""); // 存储确认 PIN 码

    const focusedPin = ref(false); // 控制 PIN 输入框焦点

    const focusedConfirmPin = ref(false); // 控制 Confirm PIN 输入框焦点

    const showKeyboard = ref(false); // 控制数字键盘显示与隐藏

    const onClickLeft = () => {
      route.push("/my"); // 返回上一页
    };

    const onSubmit = async () => {
      if (pin.value.length < 6) {
        _Toast.fail("Please enter the 6-digit PIN.");
      } else if (confirmPin.value !== pin.value) {
        _Toast.fail("PIN and Confirm PIN do not match.");
      } else {
        const loginName = localStorage.getItem("userInfo"); // 从本地存储获取 loginName

        const perUserInfo = JSON.parse(loginName);

        if (!perUserInfo) {
          _Toast.fail("Login name is missing.");

          return;
        } // 调用 setPinPwd 接口


        const response = await setPinPwd({
          loginName: perUserInfo.loginName,
          password: pin.value
        });

        if (response.code == 1) {
          // 成功设置 PIN 后调用 getUserInfo
          const userInfoResponse = await getUserInfo();

          if (userInfoResponse.code == 1) {
            const userInfo = userInfoResponse.data; // 获取用户信息

            localStorage.setItem("userInfo", JSON.stringify(userInfo)); // 更新本地存储中的用户信息

            _Toast.success("pinCode successful!"); // 登录成功提示


            setTimeout(() => {
              route.push("/my"); // 成功后跳转到我的页面
            }, 2000);
          } else {
            _Toast.fail("Failed to retrieve user information.");
          }
        } else {
          _Toast.fail("Failed to set PIN.");
        }
      }
    };

    const onPinFocus = () => {
      focusedPin.value = true;
      focusedConfirmPin.value = false;
    };

    const onConfirmPinFocus = () => {
      focusedConfirmPin.value = true;
      focusedPin.value = false;
    };

    const onBlur = () => {
      focusedPin.value = false;
      focusedConfirmPin.value = false;
    };

    return {
      pin,
      confirmPin,
      focusedPin,
      focusedConfirmPin,
      showKeyboard,
      onClickLeft,
      onPinFocus,
      onConfirmPinFocus,
      onBlur,
      onSubmit
    };
  }

};